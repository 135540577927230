import React from 'react'
import { Typography, Grid, Box, Button } from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import { Link } from 'react-router-dom'
import Footer from '../component_library/Footer'
import URLMap from '../URLMap'
import { releaseYourselfUrl } from '../shared/Constants'
import Video from '../component_library/Video'

const About: React.FC<{}> = () => {
  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h1">About Us</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Video url={releaseYourselfUrl} />
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h3">Why OriginalDog?</Typography>
            <Typography>
              OriginalDog is a brand that is inspired, created and run by
              musicians and independent artists.
            </Typography>
            <Typography>
              We believe that people who choose to make original music deserve
              the same spotlight and funding as the cinema and international
              music scene, irrespective of language, location or age. We
              developed OriginalDog to support those who choose to be more than
              copycats. By providing free marketing, designing services and
              funding tools for music production, we hope to level the playing
              field and give all musicians an equal opportunity.
            </Typography>
            <Typography variant="h3">Who/what is an OriginalDog?</Typography>
            <Typography>Anyone who makes original music.</Typography>
            <Typography variant="h3">How do we support?</Typography>
            <Typography variant="h6">Marketing support</Typography>
            <Typography>
              We don’t expect every artist to have a marketing or communications
              degree, nor do we want that to be a hinderance to their success.
              We provide the following services to our artists absolutely for
              free:
              <ul>
                <li>
                  OD profile - A mini website for our OD’s which can act as a
                  press kit, musical CV or tool to present themselves to their
                  fans/potential fans.
                </li>
                <li>Marketing strategy, design and communication assistance</li>
                <li>Social Media articles and coverage for new releases</li>
                <li>Fan engagement activities</li>
              </ul>
            </Typography>
            <Typography variant="h6">FanFunding</Typography>
            <Typography>
              Music production is expensive, especially if done without
              compromise. Independent musicians usually have to take on these
              expenses themselves, on top of rent, food etc...This might
              entirely discourage independent musicians from putting out new
              music.
            </Typography>
            <Typography>
              We developed the concept of FanFunding to solve this - A focussed
              and transparent crowdfunding tool exclusively for original music.
              By having fans of original artists and original music generally
              visiting the platform, a campaign can be completed not only by an
              artist’s fans, but also by people who have just come across their
              music and want to support them.{' '}
              <b>100% of the funds received go straight to the artist.</b>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2} mb={4}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                to={URLMap.artistList.url()}
                component={Link}
              >
                Discover our Artists
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default About
