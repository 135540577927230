import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'

const theme: ThemeOptions = createMuiTheme({
  typography: {
    fontFamily: 'Nunito, Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      '@media (min-width: 600px)': {
        fontSize: '3rem',
      },
    },
    h2: {
      fontSize: '2rem',
      '@media (min-width: 600px)': {
        fontSize: '2rem',
      },
      marginBottom: '0.7rem',
    },
    h3: {
      fontSize: '1.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    body1: {
      marginBottom: '1em',
    },
  },
  overrides: {
    MuiButton: {
      text: {
        fontWeight: 'bold',
      },
      contained: {
        fontWeight: 'bold',
      },
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#f50057',
    },
  },
})

export default theme
