import React, { Component, createContext } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import {
  Payment,
  PaymentDocument,
  PaymentRequest,
  PayURequestAugmentation,
} from './types/payment'

interface IProps {}

interface IState {
  payments: PaymentDocument[] | null
}

export class PaymentProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      // in contrast to docs/RetrievingDataFromFirestore.md, we only set up the listener on the first request
      // see issue 102 for details
      payments: null,
    }
  }

  supportCampaign = (
    payment: PaymentRequest,
  ): Promise<PayURequestAugmentation> => {
    if (process.env.NODE_ENV === 'development') {
      firebase.functions().useFunctionsEmulator('http://localhost:5001')
    }

    return firebase
      .functions()
      .httpsCallable('contribute')(payment)
      .then(res => res.data)
  }

  // see docs/RetrievingDataFromFirestore.md for an explanation of this pattern
  // see issue 102 for details on why we're setting up the listener on this call
  getPayments = (): Promise<PaymentDocument[]> => {
    if (this.state.payments === null) {
      var initialized = false
      return new Promise(resolve =>
        firebase
          .firestore()
          .collection('payments')
          .onSnapshot(snapshot => {
            const paymentDocuments = snapshot.docs.map(doc => ({
              id: doc.id,
              payment: doc.data() as Payment,
            }))
            this.setState({ payments: paymentDocuments })
            if (!initialized) {
              initialized = true
              resolve(paymentDocuments)
            }
          }),
      )
    } else {
      return Promise.resolve(this.state.payments)
    }
  }

  // this doesn't use the paymentsPromise because that promise will fail on the client side.
  // see issue 102 for a potential fix
  getPayment = (id: string): Promise<Payment | null> =>
    firebase
      .firestore()
      .collection('payments')
      .doc(id)
      .get()
      .then(doc => (doc ? (doc.data() as Payment) : null))

  updatePayment = (id: string, update: Partial<Payment>): Promise<void> =>
    firebase.firestore().collection('payments').doc(id).update(update)

  render() {
    const { supportCampaign, getPayment, getPayments, updatePayment } = this
    return (
      <PaymentContext.Provider
        value={{ supportCampaign, getPayment, getPayments, updatePayment }}
      >
        {this.props.children}
      </PaymentContext.Provider>
    )
  }
}

type PaymentContextProps = {
  supportCampaign(payment: PaymentRequest): Promise<PayURequestAugmentation>
  getPayment(id: string): Promise<Payment | null>
  getPayments(): Promise<PaymentDocument[]>
  updatePayment(id: string, update: Partial<Payment>): Promise<void>
}

export const PaymentContext: React.Context<PaymentContextProps> = createContext(
  {} as PaymentContextProps,
)
