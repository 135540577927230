import React, { useContext } from 'react'
import { Typography, Grid, Box, Button } from '@material-ui/core'

import { AuthContext } from '../shared/AuthContext'
import { SnackbarContext } from '../shared/SnackbarContext'
import CoreAppBar from '../component_library/CoreAppBar'
import Footer from '../component_library/Footer'

const ProfileNotVerified = () => {
  const { sendVerificationLink } = useContext(AuthContext)
  const { wrapWithSnackbar } = useContext(SnackbarContext)

  const sendVerification = () => {
    wrapWithSnackbar(sendVerificationLink())
  }

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h3">Awaiting email verification</Typography>
            <Typography variant="body1">
              Check your email for a verification link
            </Typography>
            <Button onClick={sendVerification}>Resend the email</Button>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default ProfileNotVerified
