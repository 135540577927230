import {
  Button,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core'
import { differenceInDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  CampaignDocument,
  CAMPAIGN_STATUS,
  DETAILED_CAMPAIGN_STATUS,
  getDetailedStatus,
} from '../shared/types/campaign'
import URLMap from '../URLMap'
import { Utilities } from '../Utilities'

interface IProps {
  campaignDoc: CampaignDocument
  onlyShowIfApproved: boolean // only show the campaign if it is approved
}

const Root = styled(Paper)`
  padding: 1rem;
  margin-bottom: 1rem;
`

const Campaign: React.FC<IProps> = ({ campaignDoc, onlyShowIfApproved }) => {
  const { id, campaign } = campaignDoc
  const { title, totalContributions, goal } = campaign
  const [detailedStatus, setDetailedStatus] = useState(
    DETAILED_CAMPAIGN_STATUS.INVALID,
  )

  useEffect(() => setDetailedStatus(getDetailedStatus(campaign)), [campaign])

  if (onlyShowIfApproved && campaign.status !== CAMPAIGN_STATUS.ACTIVE) {
    return <></>
  }

  let actionComponent = null
  switch (detailedStatus) {
    case DETAILED_CAMPAIGN_STATUS.REQUESTED:
      actionComponent = <Typography>Awaiting Approval</Typography>
      break
    case DETAILED_CAMPAIGN_STATUS.DECLINED:
      actionComponent = <Typography>Campaign Request Declined</Typography>
      break
    case DETAILED_CAMPAIGN_STATUS.FUNDED:
      actionComponent = <Typography>Campaign Fully Funded</Typography>
      break
    case DETAILED_CAMPAIGN_STATUS.PENDING:
      const daysUntil = differenceInDays(campaign.start, new Date())
      actionComponent = (
        <Typography>
          Campaign Pending |{' '}
          {daysUntil <= 1
            ? '1 day until start'
            : `${daysUntil} days until start`}
        </Typography>
      )
      break
    case DETAILED_CAMPAIGN_STATUS.UNDERFUNDED:
      actionComponent = <Typography>Campaign Underfunded</Typography>
      break
    case DETAILED_CAMPAIGN_STATUS.ACTIVE:
      const daysLeft = differenceInDays(campaign.end, new Date())
      actionComponent = (
        <Box>
          <Button
            variant="contained"
            color="secondary"
            to={URLMap.fanfund.url(id)}
            component={Link}
          >
            Support this campaign
          </Button>
          <br />
          <br />
          <Typography align="center">
            {daysLeft <= 1 ? '1 day left!' : `${daysLeft} days left`}
          </Typography>
        </Box>
      )
      break
    default:
      actionComponent = <Typography>Invalid Campaign</Typography>
  }

  return (
    <Root>
      <Typography variant="h3" align="center">
        {title}
      </Typography>
      <LinearProgress
        variant="determinate"
        style={{ height: 8 }}
        value={100 * Math.min(totalContributions / goal, 1)}
        color="secondary"
      />
      <Typography>
        {`${Utilities.addSeparator(totalContributions)} / ${Utilities.addSeparator(goal)}`}
      </Typography>
      <Grid container justify="center">
        {actionComponent}
      </Grid>
    </Root>
  )
}

export default Campaign
