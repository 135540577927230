import React from 'react'
import { Typography, Grid, Box } from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import Footer from '../component_library/Footer'

const About = () => {
  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h1">Terms and Conditions Artist</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography>
              'The Original Dog' is the trading name for{' '}
              <strong>Original Dog Media Private Limited.</strong> For the
              purposes of this document, The Original Dog shall be referred to
              as the "Agent”. The term “Artist” is not specifically only a
              single performer, but is a generic term that applies to all
              performers, entertainers, DJs, band members, duos, and solo
              artists.
            </Typography>
            <Typography>
              The term “Donor” refers to the person who intends to fund an
              Artist promoted on the Agent’s website. The primary role of the
              Agent is to provide a platform for the Artist to create a profile
              and upload their original work to the aforementioned platform i.e.
              the Agent’s website, "originaldog.com". The Agent further
              undertakes to market and promote the Artist through funds,
              received by running a crowdfunding campaign. The purpose of this
              campaign, which will be hosted on the website, is to attract
              Donors to fund a particular Artist.
            </Typography>
            <Typography>
              This contract is between the Artist and the Agent. Any
              responsibility for the fulfilment of this contract is between the
              Artist and the Agent. The Agent accepts no liability for
              nonfulfillment or breach of contract save any breach of the
              Agent’s Responsibilities.
            </Typography>
            <Typography>
              The terms and conditions between the Artist and the Agent in the
              form of warranties are enumerated below. By agreeing to the
              electronic acceptance, the Artist agrees to these terms. This
              document may be used in conjunction with a Rider and/or contract.
              Although the contract is divided into sections to draw special
              attention to the warranties provided for by each party, it should
              be read in its entirety by all persons involved to gain a broad
              understanding of what is expected of each party.
            </Typography>
            <Typography variant="h3">WARRANTIES OF ARTIST and AGENT</Typography>
            <Typography>
              <ul>
                <li>
                  The Artist undertakes that their personal details are
                  accurate. The Artist undertakes that they shall upload only
                  their original work on the Agent’s website and takes full
                  responsibility for any music or videos they upload on to the
                  same.
                </li>
                <li>
                  The Artist undertakes that the Agent will be indemnified from
                  any copyright claims on material uploaded by the Artist. The
                  Artist also undertakes that there is no plagiarism present in
                  the content of their uploaded work; if it is found that the
                  Artist has plagiarised the work of a third party, the Agent
                  has a right to claim damages over and above than that claimed
                  by the third party.
                </li>
                <li>
                  The Artist agrees that upon completion of a successful
                  crowdfunding campaign, the money received from the Donor on
                  their behalf by the Agent will be used directly for the
                  service they have opted for. The Agent shall not entertain any
                  claims made for the money to be directly given to the artists
                  unless agreed upon in prior by both parties.
                </li>
                <li>
                  The Artist undertakes that they shall perform to a standard
                  previously represented by and expected of them, and give a
                  true representation of their recorded material, and the Artist
                  is giving the Agent a complete consent for uploading the
                  Artist’s material to be viewed by the public.
                </li>
                <li>
                  The Artist undertakes that once the Artist’s material is
                  uploaded online through the Agent’s website, the Artist has no
                  objection for the Agent to use the same for marketing and
                  promoting the Artist anywhere in the world and that the Artist
                  will not claim copyright violation.
                </li>
                <li>
                  The Artist undertakes that they shall not upload any illegal
                  or pornographic material on the Agent’s website.
                </li>
                <li>
                  The Artist undertakes that they have not paid any
                  consideration to the Agent for the services rendered.
                </li>
                <li>
                  The Agent reserves the right to edit or remove any
                  material/information pertaining to the Artist as per their
                  discretion.
                </li>
                <li>
                  The Agent reserves the right to remove an artist at anytime
                  from their website. In the event that a crowdfunding campaign
                  has already begun for a particular Artist and for whatever
                  reason the Agent decides to remove an Artist, the fund
                  collected for that particular Artist shall be returned to the
                  Donor by the Agent.
                </li>
                <li>
                  Once a crowdfunding campaign is fulfilled, the service
                  requested will be arranged within 30 days. If there is a delay
                  due to any unforeseen circumstances the Agent and the Artist
                  shall mutually decide on the days to be extended.
                </li>
              </ul>
            </Typography>
            <Typography variant="h3">Arbitration Clause:-</Typography>
            <Typography>
              In case of any dispute that may arise in relation to this
              agreement between the parties, the same shall be referred to a
              sole arbitrator to be mutually appointed by the parties in
              accordance with the Arbitration and Conciliation Act, 1996. The
              award passed by the sole arbitrator shall be binding on both
              parties. The place for arbitration shall be at Chennai, Tamil
              Nadu, India.
            </Typography>
            <Typography variant="h3">Terms and Conditions Donor</Typography>
            <Typography>
              'The Original Dog' is the trading name for Original Dog Media
              Private Ltd. For the purposes of this document, The Original Dog
              shall be referred to as the "Agent". The term “Artist” is not
              specifically only a single performer, but it is a generic term
              that applies to all performers, entertainers, DJs, band members,
              duos, and solo artists. The term “Donor” refers to the person who
              intends to fund an Artist promoted on the Agent’s website.
            </Typography>
            <Typography>
              The primary work of the Agent is to provide a platform for the
              Artist to upload their musical work for it to be viewed by the
              Donor or the public. If a donor is interested in funding a
              particular Artist, the Donor has to approach the Agent to fund the
              Artist. By acceding to this agreement the Donor provides for the
              warranties towards the Agent, for funding an artist, as follows.
            </Typography>
            <Typography variant="h3">WARRANTIES OF DONOR</Typography>
            <Typography>
              <ul>
                <li>
                  The Donor can choose an artist from the Agent’s portal. The
                  Donor, if interested in a particular Artist, has to approach
                  the Agent to fund the Artist.
                </li>
                <li>
                  The Donor can specify for the funding to be allotted to a
                  particular Artist. The Agent shall have the right to use the
                  Donor’s fund towards promoting the Artist as per the Agent’s
                  discretion. The Agent shall never be accountable to the Donor
                  for the amount funded by him.
                </li>
                <li>
                  The money donated by the donor will be received entirely by
                  Original Dog against a service requested by the artist or
                  band. Donations will not go directly to the to the Artist.
                </li>
                <li>
                  All money once donated is final and will not be returned.
                </li>
                <li>
                  The donor should not expect any monetary or in-kind returns
                  for their donation unless otherwise specified by either the
                  artist or the agent.
                </li>
              </ul>
            </Typography>
            <Typography variant="h3">Arbitration Clause:-</Typography>
            <Typography>
              In case of any dispute that may arise in relation to this
              agreement between the parties, the same shall be referred to a
              sole arbitrator to be mutually appointed by the parties in
              accordance with the Arbitration and Conciliation Act, 1996. The
              award passed by the sole arbitrator shall be binding on both
              parties. The place for arbitration shall be at Chennai, Tamil
              Nadu, India.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default About
