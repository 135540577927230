import React, { useContext } from 'react'
import styled from 'styled-components'
import { Typography, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import URLMap from '../URLMap'
import theme from '../Theme'
import { AuthContext, AuthState } from './../shared/AuthContext'
import logo from '../assets/logo.png'
import { adminEmail } from '../shared/Constants'

const Root = styled.div`
  height: 28rem;
  ${theme?.breakpoints?.up && theme.breakpoints.up('md')} {
    height: 16rem;
  }
`

const ContentContainer = styled.div`
  margin-bottom: 2rem;
  text-align: center;
  ${theme?.breakpoints?.up && theme.breakpoints.up('md')} {
    margin-bottom: 0;
    text-align: left;
  }
`

const Logo = styled.img`
  width: 8rem;
  margin-bottom: 1rem;
`

const ExternalLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-zize: 0.8rem;
  &:hover {
    text-decoration: underline;
  }
`

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-zize: 0.8rem;
  &:hover {
    text-decoration: underline;
  }
`

const StyledFooter = styled.div`
  background-color: #111111;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px 0;
  height: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme?.breakpoints?.up && theme.breakpoints.up('md')} {
    height: 16rem;
  }
`

const Copyright = styled(Typography)`
  font-size: 0.8rem;
`

const Footer = () => {
  const auth = useContext(AuthContext)
  const currentYear = new Date().getFullYear()
  return (
    <Root>
      <StyledFooter>
        <Grid item container justify="center" md={8}>
          <Grid item xs={12} md={3}>
            <ContentContainer>
              <Link to={URLMap.root.url()}>
                <Logo src={logo} />
              </Link>
              <Copyright variant="body1">
                &copy; {currentYear} All Rights Reserved
              </Copyright>
              <StyledLink to={URLMap.termsAndConditions.url()}>
                Terms and Conditions
              </StyledLink>
            </ContentContainer>
          </Grid>
          <Grid item xs={12} md={3}>
            <ContentContainer>
              {auth.authState === AuthState.AUTHENTICATED && auth?.user?.uid && (
                <>
                  <StyledLink to={URLMap.profileView.url(auth.user.uid)}>
                    Profile
                  </StyledLink>
                  <br />
                </>
              )}
              {auth.authState === AuthState.UNAUTHENTICATED && (
                <>
                  <StyledLink to={URLMap.register.url()}>
                    Create a profile
                  </StyledLink>
                  <br />
                </>
              )}
              <StyledLink to={URLMap.artistList.url()}>Artists</StyledLink>
              <br />
              {auth.authState === AuthState.UNAUTHENTICATED && (
                <>
                  <StyledLink to={URLMap.login.url()}>Login</StyledLink>
                  <br />
                </>
              )}
              <>
                <StyledLink
                  to={
                    auth.authState === AuthState.AUTHENTICATED &&
                    auth?.user?.uid
                      ? URLMap.createCampaign.url()
                      : URLMap.login.url()
                  }
                >
                  Create a Campaign
                </StyledLink>
                <br />
              </>
              <StyledLink to={URLMap.about.url()}>About us</StyledLink>
            </ContentContainer>
          </Grid>
          <Grid item xs={12} md={3}>
            <ContentContainer>
              <Typography variant="h6">Contact us</Typography>
              <ExternalLink href="tel:+91 98404 32029" target="_blank">
                +91 98404 32029
              </ExternalLink>
              <br />
              <ExternalLink
                href={`mailto:${adminEmail}`}
                target="_blank"
              >
                {adminEmail}
              </ExternalLink>
            </ContentContainer>
          </Grid>
        </Grid>
      </StyledFooter>
    </Root>
  )
}

export default Footer
