import React, { useState, useContext } from 'react'
import { Button, Typography, Grid, Box, TextField } from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { AuthContext } from '../shared/AuthContext'
import URLMap from '../URLMap'
import styled from 'styled-components'
import Footer from '../component_library/Footer'
import * as EmailValidator from 'email-validator'

const StyledLink = styled(Link)`
  color: #f50057;
  text-decoration: none;
`

const ResetPassword = () => {
  const auth = useContext(AuthContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string>()
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: ({ email }) => {
      setIsSubmitting(true)
      setError(undefined)
      auth
        .resetPassword(email)
        .then(() => {
          setEmailSubmitted(true)
        })
        .catch(err => {
          setError(
            (err && err.message) || 'An error occurred. Please try again.',
          )
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    },
    validate: ({ email }) => {
      return {
        ...(!EmailValidator.validate(email) && {
          email: 'Please provide a properly formatted email address',
        }),
      }
    },
  })

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h3">Reset Password</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <form onSubmit={formik.handleSubmit}>
              <Box mt={2}>
                <TextField
                  error={formik.touched.email && formik.errors.email != null}
                  name="email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                  fullWidth
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              {error && (
                <Box textAlign="center" mt={2}>
                  <Typography component="p" color="secondary">
                    {error}
                  </Typography>
                </Box>
              )}
              {emailSubmitted && (
                <Box textAlign="center" mt={2}>
                  <Typography component="p">
                    Password reset email sent!
                  </Typography>
                </Box>
              )}
              {!emailSubmitted && (
                <Box textAlign="center" mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Box>
              )}
            </form>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Box textAlign="center" mt={2}>
              Don't have an account?{' '}
              <StyledLink to={URLMap.register.url()}>Register</StyledLink>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default ResetPassword
