import { Box, Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import CoreAppBar from '../component_library/CoreAppBar'
import URLMap from '../URLMap'

const NotFound = () => (
  <>
    <CoreAppBar />
    <Box p={2} mt={2} textAlign="center">
      <Grid container justify="center">
        <Grid item xs sm={8}>
          <Typography variant="h1">Page not found</Typography>
          <Box mt={4}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              to={URLMap.root.url}
              component={Link}
            >
              Home
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
)

export default NotFound
