const URLMap = {
  root: {
    path: `/`,
    url: () => `/`,
  },
  about: {
    path: `/about`,
    url: () => `/about`,
  },
  createCampaign: {
    path: `/create-campaign`,
    url: () => `/create-campaign`,
  },
  login: {
    path: `/login`,
    url: () => `/login`,
  },
  resetPassword: {
    path: `/reset-password`,
    url: () => `/reset-password`,
  },
  register: {
    path: `/register`,
    url: () => `/register`,
  },
  profileView: {
    path: `/profile/view/:uid`,
    url: (uid: string) => `/profile/view/${uid}`,
  },
  profileEdit: {
    path: `/profile/edit`,
    url: () => `/profile/edit`,
  },
  profileNotVerified: {
    path: `/profile/notverified`,
    url: () => `/profile/notverified`,
  },
  artistList: {
    path: `/artists`,
    url: () => `/artists`,
  },
  termsAndConditions: {
    path: `/terms-and-conditions`,
    url: () => `/terms-and-conditions`,
  },
  fanfund: {
    path: `/fanfund/:id`,
    url: (id: string) => `/fanfund/${id}`,
  },
  notVerified: {
    path: `/not-verified`,
    url: () => `/not-verified`,
  },
  payment: {
    path: '/payment/:id',
    url: (id: string) => `/payment/${id}`,
  },
  ODYouTube: {
    url: () => `https://www.youtube.com/channel/UCCYHg0Kfhpebu-JFpIdZVgA`
  }
}

export default URLMap
