import React, { useContext, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CoreAppBar from '../component_library/CoreAppBar'
import { Button, Typography, Grid, Box, TextField } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { AuthContext } from '../shared/AuthContext'
import { useFormik } from 'formik'
import styled from 'styled-components'
import URLMap from '../URLMap'
import Footer from '../component_library/Footer'
import * as EmailValidator from 'email-validator'

const StyledLink = styled(Link)`
  color: #f50057;
  text-decoration: none;
`

interface IProps {}

const Register: React.FC<RouteComponentProps<IProps>> = (
  props: RouteComponentProps,
) => {
  const auth = useContext(AuthContext)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: ({ name, email, password }) => {
      setFormErrorMessage('')
      setIsFormSubmitting(true)
      auth
        .register(name, email, password)
        .then(() => props.history.push(URLMap.profileEdit.url()))
        .catch(error => {
          setFormErrorMessage(error.message)
          setIsFormSubmitting(false)
        })
    },
    validate: ({ name, email, password, confirmPassword }) => {
      return {
        ...(!name && { name: 'Please provide your name' }),
        ...(!EmailValidator.validate(email) && {
          email: 'Please provide a properly formatted email address',
        }),
        ...((!password || password.length < 8) && {
          password: 'Please provide a password at least 8 characters long',
        }),
        ...(confirmPassword !== password && {
          confirmPassword: 'Please ensure your passwords match',
        }),
      }
    },
  })

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h3">Register a new account</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <form onSubmit={formik.handleSubmit}>
              <Box mt={2}>
                <TextField
                  error={formik.touched.name && formik.errors.name != null}
                  name="name"
                  label="Name"
                  type="text"
                  variant="outlined"
                  helperText={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null
                  }
                  fullWidth
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  error={formik.touched.email && formik.errors.email != null}
                  name="email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                  fullWidth
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  error={
                    formik.touched.password && formik.errors.password != null
                  }
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  helperText={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : null
                  }
                  fullWidth
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  error={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword != null
                  }
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? formik.errors.confirmPassword
                      : null
                  }
                  fullWidth
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box textAlign="center" mt={2}>
                <Typography component="p" color="secondary">
                  {formErrorMessage.length ? formErrorMessage : '\u00A0'}
                </Typography>
              </Box>
              <Box textAlign="center" mt={2}>
                <Button
                  disabled={isFormSubmitting}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Box textAlign="center" mt={2}>
              Have an account?{' '}
              <StyledLink to={URLMap.login.url()}>Login</StyledLink>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default withRouter(Register)
