import React, { useContext, useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { format } from 'date-fns'

import { Typography, Grid, Box } from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import Footer from '../component_library/Footer'
import { PaymentContext } from '../shared/PaymentContext'
import { Payment, PaymentStatus } from '../shared/types/payment'
import {
  LOADING,
  LoadingType,
  LoadingData,
} from '../shared/component_library/LoadingData'
import { adminEmail } from '../shared/Constants'

interface RouteMatchProps extends RouteComponentProps<{ id: string }> {}

const PaymentView: React.FC<RouteMatchProps> = (props: RouteMatchProps) => {
  const { getPayment } = useContext(PaymentContext)
  // null implies the payment doesn't exist or access to it has expired
  const [payment, setPayment] = useState<Payment | null | LoadingType>(LOADING)

  useEffect(() => {
    getPayment(props.match.params.id)
      .then(setPayment)
      .catch(() => setPayment(null))
  }, [props.match.params.id, getPayment])

  if (payment == null) {
    return (
      <>
        <CoreAppBar />
        <Box p={2} mt={2} textAlign="center">
          <Grid container justify="center">
            <Grid item xs sm={6}>
              <Typography variant="h3">Inaccessible Payment</Typography>
              <Typography variant="body1">
                The payment receipt is invalid or expired.
              </Typography>
              <Typography>
                Please contact{' '}
                <a
                  href={`mailto:${adminEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {adminEmail}
                </a>{' '}
                for details
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }

  return (
    <>
      <CoreAppBar />
      <LoadingData data={payment}>
        {payment !== LOADING && (
          <Box p={2} mt={2} textAlign="center">
            <Grid container justify="center">
              <Grid item xs sm={6}>
                <Typography variant="h3">
                  {payment.status === PaymentStatus.COMPLETE
                    ? 'Payment Complete'
                    : payment.status === PaymentStatus.FAILED
                    ? 'Payment Failed'
                    : 'Payment Status Unknown'}
                </Typography>
                {payment.status === PaymentStatus.PENDING && (
                  <Typography>
                    Inconsistency detected. We are unsure if the payment has
                    completed. Please contact {adminEmail}
                  </Typography>
                )}
                <Typography>
                  <strong>Payment ID:</strong> {props.match.params.id}
                </Typography>
                <Typography>
                  <strong>Campaign ID:</strong> {payment.campaignId}
                </Typography>
                <Typography>
                  <strong>Name:</strong> {payment.firstname}
                </Typography>
                <Typography>
                  <strong>Email:</strong> {payment.email}
                </Typography>
                <Typography>
                  <strong>Amount:</strong> {payment.amount} Rs
                </Typography>
                <Typography>
                  <strong>Date:</strong>{' '}
                  {format(new Date(payment.timestamp), 'dd/MM/yyyy')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </LoadingData>

      <Footer />
    </>
  )
}

export default withRouter(PaymentView)
