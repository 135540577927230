import React from 'react'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import facebookIcon from '../assets/facebook.svg'
import twitterIcon from '../assets/twitter.svg'
import instagramIcon from '../assets/instagram.svg'
import youtubeIcon from '../assets/youtube.svg'
import spotifyIcon from '../assets/spotify.png'
import appleMusicIcon from '../assets/apple-music.png'

const Icon = styled(Avatar)`
  height: 2rem;
  width: 2rem;
  padding: 0.5rem;
`

const iconMap = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  instagram: instagramIcon,
  youtube: youtubeIcon,
  spotify: spotifyIcon,
  appleMusic: appleMusicIcon,
}

const SocialMediaIcon: React.FC<{
  href: string
  icon:
    | 'facebook'
    | 'twitter'
    | 'instagram'
    | 'youtube'
    | 'spotify'
    | 'appleMusic'
}> = ({ href, icon }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Icon alt={icon} src={iconMap[icon]} variant="square" />
    </a>
  )
}

export default SocialMediaIcon
