import React, { Component, createContext } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { ArtistDocument, Artist, defaultArtist } from './types/artist'

interface IProps {}

interface IState {
  artistsPromise: Promise<ArtistDocument[]>
}

export class ArtistProvider extends Component<IProps, IState> {
  // see docs/RetrievingDataFromFirestore.md for an explanation of this pattern
  constructor(props: IProps) {
    super(props)
    var initialized = false
    this.state = {
      artistsPromise: new Promise(resolve =>
        firebase
          .firestore()
          .collection('users')
          .onSnapshot(snapshot => {
            const artistDocuments: ArtistDocument[] = snapshot.docs.map(
              doc => ({
                id: doc.id,
                artist: defaultArtist(doc.data()),
              }),
            )
            if (initialized) {
              this.setState({
                artistsPromise: Promise.resolve(artistDocuments),
              })
            } else {
              initialized = true
              resolve(artistDocuments)
            }
          }),
      ),
    }
  }

  updateArtist = (id: string | null, update: Partial<Artist>): Promise<void> =>
    id
      ? firebase
          .firestore()
          .collection('users')
          .doc(id)
          .update(
            Object.assign(update, {
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            }),
          )
      : Promise.resolve()

  getArtist = (id: string | null): Promise<Artist | null> =>
    this.state.artistsPromise
      .then(docs => docs.filter(doc => doc.id === id))
      .then(docs => (docs.length > 0 ? docs[0].artist : null))

  getArtists = (): Promise<ArtistDocument[]> => this.state.artistsPromise

  getGenres = (): Promise<string[]> =>
    this.state.artistsPromise
      .then(docs => docs.map(doc => doc.artist.genre))
      .then(genreLists => genreLists.reduce((acc, l) => acc.concat(l), []))
      .then(genres =>
        // remove duplicates
        genres.filter((genre, idx, all) => all.indexOf(genre) === idx),
      )
      .then(genres => genres.sort())

  render() {
    const { updateArtist, getArtist, getArtists, getGenres } = this
    return (
      <ArtistContext.Provider value={{ updateArtist, getArtist, getArtists, getGenres }}>
        {this.props.children}
      </ArtistContext.Provider>
    )
  }
}

type ArtistContextProps = {
  updateArtist(id: string | null, update: Partial<Artist>): Promise<void>
  getArtist(id: string | null): Promise<Artist | null>
  getArtists(): Promise<ArtistDocument[]>
  getGenres(): Promise<string[]>
}

export const ArtistContext: React.Context<ArtistContextProps> = createContext(
  {} as ArtistContextProps,
)
