import { ArtistDocument } from './shared/types/artist'
import {
  MIN_CATEGORY_SIZE,
  NEW_ARTIST_WINDOW_SECONDS,
} from './shared/Constants'

export interface ArtistCategory {
  name: string
  documents: ArtistDocument[]
}

export class ArtistCategories {
  /**
   * Note: this function is simple but inefficient. There is plenty of room for optimisations if necessary
   */
  static categorise(
    documents: ArtistDocument[],
    artistsWithActiveCampaigns: string[],
  ): ArtistCategory[] {
    const popular: ArtistCategory = {
      name: 'Most Popular',
      documents: documents.filter(doc => doc.artist.popular),
    }
    const newReleases: ArtistCategory = {
      name: 'New Song Releases',
      documents: documents.filter(doc => doc.artist.new_releases),
    }
    const activeCampaigns: ArtistCategory = {
      name: 'Active Campaigns',
      documents: documents.filter(doc =>
        artistsWithActiveCampaigns.includes(doc.id),
      ),
    }
    const threshold = Date.now() / 1000 - NEW_ARTIST_WINDOW_SECONDS
    const newArtist: ArtistCategory = {
      name: 'New OriginalDogs',
      documents: documents.filter(
        doc => doc.artist.createdAt.seconds > threshold,
      ),
    }

    const genres = documents
      .map(doc => doc.artist.genre)
      // combine all genres from all artists into a single list
      .reduce((acc, l) => acc.concat(l), [])
      // remove duplicates
      .filter((genre, idx, all) => all.indexOf(genre) === idx)
      .sort()

    const groupedByGenre = genres
      .map(genre => ({
        name: genre,
        documents: documents.filter(doc => doc.artist.genre.includes(genre)),
      }))
      .filter(category => category.documents.length >= MIN_CATEGORY_SIZE)
      // sort in descending order of the number of artists
      .sort( (x, y) => y.documents.length - x.documents.length)

    const groups = [popular]
      .concat(activeCampaigns)
      .concat(newArtist)
      .concat(newReleases)
      .concat(groupedByGenre)

    const allGroupedArtists = groups
      .map(category => category.documents)
      // combine all artist documents into a single list
      .reduce((acc, l) => acc.concat(l), [])
      // use the artist ID for simplicity
      .map(doc => doc.id)
      // remove duplicates
      .filter((genre, idx, all) => all.indexOf(genre) === idx)

    const ungrouped: ArtistCategory = {
      name: 'Other',
      documents: documents.filter(doc => !allGroupedArtists.includes(doc.id)),
    }

    return groups
      .concat(ungrouped)
      .filter(category => category.documents.length > 0)
  }
}
