import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../shared/AuthContext'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons'
import FileStorage, { FileRecord } from '../shared/FileStorage'
import ImageSelector from '../component_library/ImageSelector'
import { Paper } from '@material-ui/core'
import { useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'
import {
  LOADING,
  LoadingData,
  LoadingType,
} from '../shared/component_library/LoadingData'

const HiddenRadioInput = styled.input`
  position: fixed;
  opacity: 0;
  pointer-events: none;
`
const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ImageContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const ImageLabel = styled.label`
  cursor: pointer;
  position: relative;
  display: block;
  width: 8rem;
  height: 8rem;
  @media (min-width: 960px) {
    width: 10rem;
    height: 10rem;
  }
  input:checked ~ svg {
    display: block;
  }
`

const ExpandedPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const Image = styled.img.attrs({ alt: '' })`
  width: 100%;
`

const ImageSelectedIcon = styled(CheckCircleIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #fff;
  border-radius: 50%;
  display: none;
`

interface IProps {
  open: boolean
  onSelected(url: string): void
  onClose(): void
}

const Gallery: React.FC<IProps> = ({ open, onSelected, onClose }) => {
  const auth = useContext(AuthContext)
  const [imageRecords, setImageRecords] = useState<FileRecord[] | LoadingType>(
    LOADING,
  )
  const [storageErrorMessage, setStorageErrorMessage] = useState('')

  const formik = useFormik({
    initialValues: {
      filename: '',
    },
    onSubmit: ({ filename }) => {
      let url
      if (imageRecords === LOADING) {
        url = ''
      } else {
        const matchingRecords = imageRecords.filter(
          imageRecord => imageRecord.filename === filename,
        )
        url = matchingRecords.length > 0 ? matchingRecords[0].url : ''
      }
      onSelected(url)
    },
  })

  const refreshImages = () => {
    if (auth.user?.verified) {
      FileStorage.fileRecords(auth.user.uid).then(setImageRecords)
    }
  }

  useEffect(refreshImages, [auth.user])

  const upload = (file: File) => {
    if (auth.user?.verified) {
      setStorageErrorMessage('Uploading...')
      FileStorage.upload(auth.user.uid, file, uuidv4())
        .then(() => setStorageErrorMessage(''))
        .then(refreshImages)
        .catch((error: Error) => setStorageErrorMessage(error.message))
    }
  }

  const deleteSelected = (filename: string) => () =>
    auth.user?.verified
      ? FileStorage.deleteRecord(auth.user.uid, filename).then(refreshImages)
      : null

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Select image</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <LoadingData data={imageRecords}>
            {imageRecords !== LOADING && (
              <>
                <ImageSelector onSelected={upload} />
                <ImagesContainer>
                  {imageRecords.map(imageRecord => (
                    <ImageContainer key={imageRecord.filename}>
                      <ImageLabel>
                        <ExpandedPaper>
                          <HiddenRadioInput
                            name="filename"
                            type="radio"
                            value={imageRecord.filename}
                            onChange={formik.handleChange}
                          />
                          <Image src={imageRecord.url} />
                          <ImageSelectedIcon />
                        </ExpandedPaper>
                      </ImageLabel>
                      <IconButton
                        size="small"
                        onClick={deleteSelected(imageRecord.filename)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ImageContainer>
                  ))}
                </ImagesContainer>
              </>
            )}
          </LoadingData>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            Select
          </Button>
        </DialogActions>
        <Box textAlign="center" mt={2}>
          <Typography component="p" color="secondary">
            {storageErrorMessage.length ? storageErrorMessage : '\u00A0'}
          </Typography>
        </Box>
      </form>
    </Dialog>
  )
}

export default Gallery
