import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'

export interface FileRecord {
  url: string
  filename: string
}

class FileStorage {
  static USER_STORAGE_LIMIT = 10e6 // 10 MB

  static storageRef = () => firebase.storage().ref()

  static async upload(uid: string, file: File, name: string): Promise<string> {
    const usedStorage = await this.usedStorage(uid)
    if(usedStorage + file.size > this.USER_STORAGE_LIMIT) {
      return Promise.reject(new Error('Storage Limit (10MB) exceeded'))
    }

    const uploadTask = this.storageRef().child(uid).child(name).put(file)
    // Firebase errors are detected through a status listener
    // see: https://firebase.google.com/docs/storage/web/upload-files
    // convert it into a rejected promise
    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        () => {}, // ignore status updates
        error =>
          reject(
            new Error(`Upload failed with error message: ${error.message}`),
          ),
        () => resolve(uploadTask.snapshot.ref.getDownloadURL()),
      )
    })
  }

  static fileRecords(uid: string): Promise<FileRecord[]> {
    return this.storageRef()
      .child(uid)
      .listAll()
      .then(list => list.items)
      .then(items =>
        items.map(ref =>
          ref.getDownloadURL().then(url => ({
            url,
            filename: ref.name,
          })),
        ),
      )
      .then(urlPromises => Promise.all(urlPromises))
  }

  static deleteRecord(uid: string, filename: string): Promise<void> {
    return this.storageRef().child(uid).child(filename).delete()
  }

  static usedStorage(uid: string): Promise<number> {
    return this.storageRef()
      .child(uid)
      .listAll()
      .then(list => list.items)
      .then(items => items.map(ref => ref.getMetadata()))
      .then(metadataPromises => Promise.all(metadataPromises))
      .then(metadata => metadata.map(m => m.size))
      .then(sizes => sizes.reduce((total, size) => total + size, 0))
  }
}

export default FileStorage
