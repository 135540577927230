import React from 'react'
import { Button, Typography, Box } from '@material-ui/core'
import logo from '../assets/logo.png'
import styled from 'styled-components'
import heroImage from '../assets/hero-guitar.jpg'
import Footer from '../component_library/Footer'
import { Link } from 'react-router-dom'
import URLMap from '../URLMap'
import CoreAppBar from '../component_library/CoreAppBar'

const Hero = styled.div`
  background-color: #000000;
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${heroImage});
  background-position: center;
  background-size: cover;
`

const Logo = styled.img`
  width: 60vw;
`

const Tagline = styled.div`
  color: #ffffff;
  margin-top: 32px;
`

const Links = styled.div`
  margin-top: 128px;
`

const LinkButton = (text: string, url: string) => (
  <Button
    variant="contained"
    color="secondary"
    size="large"
    component={Link}
    style={{ margin: '16px' }}
    to={url}
  >
    {text}
  </Button>
)

const Home = () => {
  return (
    <>
      <CoreAppBar isStacked />
      <Hero>
        <Box>
          <Logo src={logo} alt="Original Dog Logo" />
          <Tagline>
            <Typography variant="h1">Release Yourself</Typography>
            <Typography variant="h5">
              India's first funding and marketing platform for Original Music
            </Typography>
          </Tagline>
          <Links>
            {LinkButton('Meet the OriginalDogs', URLMap.artistList.url())}
            {LinkButton('Become an OriginalDog', URLMap.register.url())}
            {LinkButton('Already an OD? Sign in', URLMap.login.url())}
            {LinkButton('About the Platform', URLMap.about.url())}
            <a
              href={URLMap.ODYouTube.url()}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ margin: '16px' }}
              >
                OD on Music
              </Button>
            </a>
          </Links>
        </Box>
      </Hero>
      <Footer />
    </>
  )
}

export default Home
