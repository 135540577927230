import React, { MutableRefObject, useRef } from 'react'
import { Button } from '@material-ui/core'

interface IProps {
  onSelected(file: File): void
}

const ImageSelector: React.FC<IProps> = ({ onSelected }) => {
  // The html input tag allows file selection through the OS
  // To use this functionality with a generic React component:
  // - hide the input tag
  // - save a reference to it
  // - pass the click event from the React button to the tag to open the picker
  // - when the input file changes, call onSelected

  const fileInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  const passClickEventToInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onChange = () => {
    const inputElement = fileInputRef.current
    if (inputElement && inputElement.files && inputElement.files.length > 0) {
      onSelected(inputElement.files[0])
    }
  }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={onChange}
      ></input>
      <Button onClick={passClickEventToInput}>Upload</Button>
    </>
  )
}

export default ImageSelector
