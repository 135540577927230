import React, { useContext, useState, useRef, useEffect } from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import {
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  Checkbox,
  Select,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Avatar,
} from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import Footer from '../component_library/Footer'
import { useFormik } from 'formik'
import * as EmailValidator from 'email-validator'
import { CampaignContext } from '../shared/CampaignContext'
import {
  getDetailedStatus,
  DETAILED_CAMPAIGN_STATUS,
  Campaign,
} from '../shared/types/campaign'
import { Artist } from '../shared/types/artist'
import {
  defaultPaymentRequest,
  PayURequestAugmentation,
  suggestedPayments,
} from '../shared/types/payment'
import { PaymentContext } from '../shared/PaymentContext'
import { ArtistContext } from '../shared/ArtistContext'
import styled from 'styled-components'
import Video from '../component_library/Video'
import {
  LOADING,
  LoadingData,
  LoadingType,
} from '../shared/component_library/LoadingData'
import URLMap from '../URLMap'
import { paymentGateway, paymentCallback } from '../shared/Constants'

const PhoneInput = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: 2rem;
  justify-content: center;
  align-items: center;
`

const TypographyPreserveSpaces = styled(Typography)`
  white-space: pre-wrap;
`

interface RouteMatchProps extends RouteComponentProps<{ id: string }> {}

const FanFund: React.FC<RouteMatchProps> = (props: RouteMatchProps) => {
  const { getCampaign } = useContext(CampaignContext)
  const { supportCampaign } = useContext(PaymentContext)
  const { getArtist } = useContext(ArtistContext)
  // null means the campaign does not exist
  const [campaign, setCampaign] = useState<Campaign | null | LoadingType>(
    LOADING,
  )
  // null means the campaign's artistId does not match any artist. This should never occur.
  const [artist, setArtist] = useState<Artist | null | LoadingType>(LOADING)
  // null means the status is unknown because the campaign doesn't exist
  const [detailedStatus, setDetailedStatus] = useState<
    DETAILED_CAMPAIGN_STATUS | null | LoadingType
  >(LOADING)
  const [payment] = useState(
    defaultPaymentRequest({ campaignId: props.match.params.id }),
  )
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')

  const maxSuggestion = suggestedPayments[suggestedPayments.length - 1]

  useEffect(() => {
    getCampaign(props.match.params.id).then(campaign => {
      setCampaign(campaign)
      setDetailedStatus(campaign ? getDetailedStatus(campaign) : null)
      if (campaign) {
        getArtist(campaign.artistId).then(setArtist)
      } else {
        setArtist(null)
      }
    })
  }, [getCampaign, getArtist, props.match.params.id])

  // The payment gateway does not include CORS headers. This means that we need to use the form action (which bypasses the CORS restriction)
  // To that end, we use the formElement variable to programmatically submit the form when we're ready
  // See: https://github.com/formium/formik/issues/556#issuecomment-472047486
  const formElement = useRef<HTMLFormElement>(null)

  // hidden parameters required by the payment form
  const callbackUrl = paymentCallback
  const serviceProvider = 'payu_paisa'

  // Using the native HTMLFormElement action is not idiomatic in React,
  // so here is an explanation for some unusual design choices
  //
  // We need to update the form with server-generated information before submitting it
  // To that end, we use the `paymentAug` state (instead of a local variable or `useRef`)
  // This ensures the hidden form elements are re-rendered on an update, even though the UI doesn't change
  //
  // The fact that the server provides additional information should not be visible to the user
  // This means we need to get the information and do the update in the onSubmit function
  // However, within that function, we need to wait for the update to complete before submitting the form,
  // which is impossible because the `setPaymentAug` is asynchronous and doesn't have a callback
  // Instead, we do the submission in a `useEffect` hook whenever `paymentAug` is set to a non-empty value
  // setPaymentAug and the useEffect hook should be thought of as a single `setPaymentAugAndSubmit` function
  //
  // There is probably a better way to do this
  const [paymentAug, setPaymentAug] = useState<PayURequestAugmentation>({
    key: '',
    txnid: '',
    productinfo: '',
    hash: '',
  })
  useEffect(() => {
    if (paymentAug.key.length > 0) {
      formElement.current?.submit()
    }
  }, [paymentAug])

  const formik = useFormik({
    initialValues: {
      ...payment,
      terms: false,
      selectedAmount: payment.amount,
      manualAmount: maxSuggestion,
    },
    onSubmit: formValues => {
      // ignore the terms, they were only used to gatekeep access to this function
      const { terms, manualAmount, selectedAmount, ...payment } = formValues
      // choose between the selected amount and the manual amount
      const modifiedPayment = Object.assign(payment, {
        amount:
          selectedAmount === maxSuggestion + 1 ? manualAmount : selectedAmount,
      })
      setFormErrorMessage('')
      setIsFormSubmitting(true)

      supportCampaign(modifiedPayment)
        .then(setPaymentAug) // don't submit the form yet. See the `paymentAug` comment
        .catch(error => {
          setFormErrorMessage(error.message)
          setIsFormSubmitting(false)
        })
    },
    validate: ({
      firstname,
      phone,
      email,
      terms,
      selectedAmount,
      manualAmount,
    }) => {
      return {
        ...(!firstname && {
          firstname: 'Please provide a name',
        }),
        ...(!phone && {
          phone: 'Please provide a contact number',
        }),
        ...(!EmailValidator.validate(email) && {
          email: 'Please provide a properly formatted email address',
        }),
        ...(!terms && {
          terms: 'Please agree to the terms and conditions',
        }),
        ...(selectedAmount === maxSuggestion + 1 &&
          manualAmount < maxSuggestion && {
            manualAmount: `Please provide a value larger than ${maxSuggestion}`,
          }),
      }
    },
  })

  return (
    <>
      <CoreAppBar />
      <LoadingData data={campaign}>
        <LoadingData data={detailedStatus}>
          <LoadingData data={artist}>
            {campaign !== LOADING &&
              detailedStatus !== LOADING &&
              artist !== LOADING && (
                <>
                  {campaign !== null && artist !== null ? (
                    <>
                      <Grid container justify="center">
                        <Grid item xs sm={6}>
                          <Hero>
                            <Avatar
                              alt="Avatar"
                              src={artist.avatarUrl}
                              style={{ width: '8rem', height: '8rem' }}
                            />
                            <Typography variant="h3">{artist.name}</Typography>
                            <br />
                            <Typography variant="h2">
                              {campaign.title}
                            </Typography>
                            <TypographyPreserveSpaces>
                              {campaign.description}
                            </TypographyPreserveSpaces>
                            {campaign.videoUrl && (
                              <Video url={campaign.videoUrl} />
                            )}
                          </Hero>
                          <Box textAlign="center">
                            <Typography variant="h3">
                              Thanks a lot for your support
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      {detailedStatus === DETAILED_CAMPAIGN_STATUS.ACTIVE ? (
                        <Box p={2}>
                          <Grid container justify="center">
                            <Grid item xs sm={6}>
                              <form
                                onSubmit={formik.handleSubmit}
                                ref={formElement}
                                action={paymentGateway}
                                method="POST"
                              >
                                {/* These hidden fields are required by the payment gateway*/}
                                <input
                                  type="hidden"
                                  name="surl"
                                  value={callbackUrl}
                                />
                                <input
                                  type="hidden"
                                  name="furl"
                                  value={callbackUrl}
                                />
                                <input
                                  type="hidden"
                                  name="service_provider"
                                  value={serviceProvider}
                                />
                                <input
                                  type="hidden"
                                  name="key"
                                  value={paymentAug.key}
                                />
                                <input
                                  type="hidden"
                                  name="txnid"
                                  value={paymentAug.txnid}
                                />
                                <input
                                  type="hidden"
                                  name="productinfo"
                                  value={paymentAug.productinfo}
                                />
                                <input
                                  type="hidden"
                                  name="hash"
                                  value={paymentAug.hash}
                                />
                                {/* The PayU money gateway requires this form to have an `amount` field. We have to calculate it based on the selectedAmount and manualAmount values */}
                                <input
                                  type="hidden"
                                  name="amount"
                                  value={
                                    formik.values.selectedAmount ===
                                    maxSuggestion + 1
                                      ? formik.values.manualAmount
                                      : formik.values.selectedAmount
                                  }
                                />
                                <Box mt={2}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel>Amount</InputLabel>
                                    <Select
                                      name="selectedAmount"
                                      value={formik.values.selectedAmount}
                                      onChange={formik.handleChange}
                                    >
                                      {suggestedPayments.map(amount => (
                                        <MenuItem key={amount} value={amount}>
                                          {amount}
                                        </MenuItem>
                                      ))}
                                      {/* use the max suggestion + 1 as an indication the manual amount should be used */}
                                      <MenuItem
                                        key="Manual"
                                        value={maxSuggestion + 1}
                                      >
                                        Manual Entry
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                                {formik.values.selectedAmount ===
                                  maxSuggestion + 1 && (
                                  <TextField
                                    error={
                                      formik.touched.manualAmount &&
                                      formik.errors.manualAmount != null
                                    }
                                    name="manualAmount"
                                    label="Amount"
                                    type="number"
                                    variant="outlined"
                                    helperText={
                                      formik.touched.manualAmount &&
                                      formik.errors.manualAmount
                                        ? formik.errors.manualAmount
                                        : null
                                    }
                                    fullWidth
                                    value={formik.values.manualAmount}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                  />
                                )}
                                <Box mt={2}>
                                  <TextField
                                    error={
                                      formik.touched.firstname &&
                                      formik.errors.firstname != null
                                    }
                                    name="firstname"
                                    label="Name"
                                    type="text"
                                    variant="outlined"
                                    helperText={
                                      formik.touched.firstname &&
                                      formik.errors.firstname
                                        ? formik.errors.firstname
                                        : null
                                    }
                                    fullWidth
                                    value={formik.values.firstname}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                  />
                                </Box>
                                <Box mt={2}>
                                  <PhoneInput
                                    error={
                                      formik.touched.phone &&
                                      formik.errors.phone != null
                                    }
                                    name="phone"
                                    label="Phone Number"
                                    type="number"
                                    variant="outlined"
                                    helperText={
                                      formik.touched.phone &&
                                      formik.errors.phone
                                        ? formik.errors.phone
                                        : null
                                    }
                                    fullWidth
                                    value={formik.values.phone}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                  />
                                </Box>
                                <Box mt={2}>
                                  <TextField
                                    error={
                                      formik.touched.email &&
                                      formik.errors.email != null
                                    }
                                    name="email"
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    helperText={
                                      formik.touched.email &&
                                      formik.errors.email
                                        ? formik.errors.email
                                        : null
                                    }
                                    fullWidth
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                  />
                                </Box>
                                <Box mt={2}>
                                  <FormControlLabel
                                    name="terms"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.terms}
                                    control={<Checkbox color="primary" />}
                                    label={
                                      <>
                                        <span>I agree with the </span>
                                        <Link
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          to={URLMap.termsAndConditions.url()}
                                        >
                                          terms and conditions.
                                        </Link>
                                      </>
                                    }
                                  />
                                </Box>
                                <Box textAlign="center" mt={2}>
                                  <Typography component="p" color="secondary">
                                    {formErrorMessage.length
                                      ? formErrorMessage
                                      : '\u00A0'}
                                  </Typography>
                                </Box>
                                <Box textAlign="center" mt={4} mb={4}>
                                  <Button
                                    disabled={
                                      isFormSubmitting ||
                                      !formik.isValid ||
                                      !formik.dirty
                                    }
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </Box>
                              </form>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : (
                        <Box p={2} mt={2} textAlign="center">
                          <Grid container justify="center">
                            <Grid item xs sm={6}>
                              <Typography variant="h6">
                                Campaign not accepting contributions
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box p={2} mt={2} textAlign="center">
                      <Grid container justify="center">
                        <Grid item xs sm={6}>
                          <Typography variant="h6">
                            Campaign or artist cannot be found
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </>
              )}
          </LoadingData>
        </LoadingData>
      </LoadingData>
      <Footer />
    </>
  )
}

export default withRouter(FanFund)
