import { ArtistDocument } from './shared/types/artist'
import {
  MIN_CATEGORY_SIZE,
  NEW_ARTIST_WINDOW_SECONDS,
} from './shared/Constants'

export class Utilities {
  static addSeparator(value: number): string {
    let start = value.toString()
    let end = ""
    while (start.length > 3) {
      end = `,${start.slice(start.length - 3)}` + end
      start = start.slice(0, start.length - 3)
    }
    return start + end
  }
}
