import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Home from './pages/Home'
import Register from './pages/Register'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
import ResetPassword from './pages/ResetPassword'
import About from './pages/About'
import ProfileEdit from './pages/ProfileEdit'
import ProfileView from './pages/ProfileView'
import CreateCampaign from './pages/CreateCampaign'
import ProfileNotVerified from './pages/ProfileNotVerified'
import ArtistList from './pages/ArtistList'
import TermsAndConditions from './pages/TermsAndConditions'
import FanFund from './pages/FanFund'
import PaymentView from './pages/PaymentView'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './Theme'
import firebase from 'firebase/compat/app'
import { getApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import firebaseConfig from './firebase_config'
import { ArtistProvider } from './shared/ArtistContext'
import { AuthProvider } from './shared/AuthContext'
import { CampaignProvider } from './shared/CampaignContext'
import { PaymentProvider } from './shared/PaymentContext'
import URLMap from './URLMap'
import { createGlobalStyle } from 'styled-components'
import { SnackbarProvider } from './shared/SnackbarContext'

firebase.initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(getAuth(), 'http://localhost:9099')
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080)
  connectFunctionsEmulator(getFunctions(getApp()), 'localhost', 5001)
}

const GlobalStyle = createGlobalStyle`
  body {
    position: relative;
    min-height: 100vh;
  }
  /* TODO: This css rule should either be created within the app theme, or as a styled component */
  .MuiTypography-root.MuiListItemText-primary {
    margin-bottom: 0;
  }
  .MuiTypography-root.MuiFormControlLabel-label {
    margin-bottom: 0;
  }
`

function App(): JSX.Element {
  return (
    <Router>
      <CssBaseline />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ArtistProvider>
            <CampaignProvider>
              <PaymentProvider>
                <SnackbarProvider>
                  <Switch>
                    <Route path="/" exact component={() => <Home />} />
                    <Route
                      path={URLMap.register.path}
                      exact
                      component={() => <Register />}
                    />
                    <Route
                      path={URLMap.profileEdit.path}
                      exact
                      component={() => <ProfileEdit />}
                    />
                    <Route
                      path={URLMap.profileView.path}
                      exact
                      component={() => <ProfileView />}
                    />
                    <Route
                      path={URLMap.profileNotVerified.path}
                      exact
                      component={() => <ProfileNotVerified />}
                    />
                    <Route
                      path={URLMap.login.path}
                      exact
                      component={() => <Login />}
                    />
                    <Route
                      path={URLMap.resetPassword.path}
                      exact
                      component={() => <ResetPassword />}
                    />
                    <Route
                      path={URLMap.about.path}
                      exact
                      component={() => <About />}
                    />
                    <Route
                      path={URLMap.artistList.path}
                      exact
                      component={() => <ArtistList />}
                    />
                    <Route
                      path="/terms-and-conditions"
                      exact
                      component={() => <TermsAndConditions />}
                    />
                    <Route
                      path={URLMap.fanfund.path}
                      exact
                      component={() => <FanFund />}
                    />
                    <Route
                      path={URLMap.createCampaign.path}
                      exact
                      component={() => <CreateCampaign />}
                    />
                    <Route
                      path={URLMap.payment.path}
                      exact
                      component={() => <PaymentView />}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </SnackbarProvider>
              </PaymentProvider>
            </CampaignProvider>
          </ArtistProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
