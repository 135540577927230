import React, { useContext, useState, useEffect } from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import {
  Typography,
  Grid,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from '@material-ui/core'
import { AuthContext, AuthState } from '../shared/AuthContext'
import { CampaignContext } from '../shared/CampaignContext'
import CoreAppBar from '../component_library/CoreAppBar'
import Campaign from '../component_library/Campaign'
import SocialMediaIcon from '../component_library/SocialMediaIcon'
import Footer from '../component_library/Footer'
import Video from '../component_library/Video'
import styled from 'styled-components'
import { ArtistContext } from '../shared/ArtistContext'
import { Artist } from '../shared/types/artist'
import Avatar from '@material-ui/core/Avatar'
import influencesIcon from '../assets/music-lover.svg'
import styleIcon from '../assets/vinyl.svg'
import performanceIcon from '../assets/microphone.svg'
import URLMap from '../URLMap'
import { CampaignDocument } from '../shared/types/campaign'
import {
  LOADING,
  LoadingData,
  LoadingType,
} from '../shared/component_library/LoadingData'

const Banner = styled.div<{ src: string }>`
  background-color: #000000;
  width: 100%;
  padding-top: 56.25%;
  background-position: center;
  background-size: cover;
  ${({ src }) => src && `background-image: url(${src});`}

  @media (min-width: 960px) {
    padding-top: 42.85%;
  }

  @media (min-width: 1920px) {
    padding-top: 50vh;
  }
`

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: 2rem;
`

const SocialLinks = styled.div`
  display: flex;
`

const YouTubeVideo = styled(Video)`
  margin-top: 4rem;
`

const About = styled.div`
  margin-top: 5rem;
`

const Attributes = styled(List)`
  margin-top: 4rem;
`

interface RouteMatchProps extends RouteComponentProps<{ uid: string }> {}

const ProfileView: React.FC<RouteMatchProps> = (props: RouteMatchProps) => {
  const auth = useContext(AuthContext)
  const { getArtist } = useContext(ArtistContext)
  const { getCampaignsByArtist } = useContext(CampaignContext)
  const [artist, setArtist] = useState<Artist | null | LoadingType>(LOADING)
  const [campaignDocs, setCampaignDocs] = useState<
    CampaignDocument[] | LoadingType
  >(LOADING)
  const [isOwnProfile, setIsOwnProfile] = useState(false)

  useEffect(() => {
    getArtist(props.match.params.uid).then(setArtist)
  }, [getArtist, props.match.params.uid])

  useEffect(() => {
    getCampaignsByArtist(props.match.params.uid).then(setCampaignDocs)
  }, [getCampaignsByArtist, props.match.params.uid])

  useEffect(
    () =>
      setIsOwnProfile(
        auth.authState === AuthState.AUTHENTICATED &&
          auth.user != null &&
          auth.user.verified &&
          auth.user.uid === props.match.params.uid,
      ),
    [auth.authState, auth.user, props.match.params.uid],
  )

  return (
    <>
      <CoreAppBar />
      <LoadingData data={artist}>
        {artist !== LOADING &&
          (artist !== null ? (
            <>
              <Banner src={artist.backgroundUrl} />
              <Box p={2} mt={2}>
                <Grid container justify="center">
                  <Grid item xs sm={6}>
                    <Hero>
                      <Avatar
                        alt="Avatar"
                        src={artist.avatarUrl}
                        style={{ width: '8rem', height: '8rem' }}
                      />
                      <Typography variant="h1">{artist.name}</Typography>
                      {artist.tagline && (
                        <Typography>{artist.tagline}</Typography>
                      )}
                      {artist.state && (
                        <Typography variant="body2">{artist.state}</Typography>
                      )}
                      {artist.city && (
                        <Typography variant="body2">{artist.city}</Typography>
                      )}
                      <SocialLinks>
                        {artist.facebookUrl && (
                          <SocialMediaIcon
                            href={artist.facebookUrl}
                            icon="facebook"
                          />
                        )}
                        {artist.twitterUrl && (
                          <SocialMediaIcon
                            href={artist.twitterUrl}
                            icon="twitter"
                          />
                        )}
                        {artist.instagramUrl && (
                          <SocialMediaIcon
                            href={artist.instagramUrl}
                            icon="instagram"
                          />
                        )}
                        {artist.youTubeUrl && (
                          <SocialMediaIcon
                            href={artist.youTubeUrl}
                            icon="youtube"
                          />
                        )}
                        {artist.spotifyUrl && (
                          <SocialMediaIcon
                            href={artist.spotifyUrl}
                            icon="spotify"
                          />
                        )}
                        {artist.appleMusicUrl && (
                          <SocialMediaIcon
                            href={artist.appleMusicUrl}
                            icon="appleMusic"
                          />
                        )}
                      </SocialLinks>
                    </Hero>
                    <LoadingData data={campaignDocs}>
                      {campaignDocs !== LOADING &&
                        campaignDocs.map(campaignDoc => (
                          <Campaign
                            key={campaignDoc.id}
                            campaignDoc={campaignDoc}
                            onlyShowIfApproved={!isOwnProfile}
                          />
                        ))}
                    </LoadingData>
                    {isOwnProfile && (
                      <Box p={2} mt={2}>
                        <Grid container justify="center">
                          <Button
                            variant="contained"
                            color="secondary"
                            to={URLMap.createCampaign.url()}
                            component={Link}
                          >
                            Create a campaign
                          </Button>
                        </Grid>
                      </Box>
                    )}
                    {artist.profileVideoUrl && (
                      <YouTubeVideo url={artist.profileVideoUrl} />
                    )}
                    <About>
                      {artist.originStory && (
                        <>
                          <Typography variant="h3">Origin Story</Typography>
                          <Typography>{artist.originStory}</Typography>
                        </>
                      )}
                      {artist.ambitions && (
                        <>
                          <Typography variant="h3">Ambitions</Typography>
                          <Typography>{artist.ambitions}</Typography>
                        </>
                      )}
                      {artist.challenges && (
                        <>
                          <Typography variant="h3">Challenges</Typography>
                          <Typography>{artist.challenges}</Typography>
                        </>
                      )}
                    </About>
                    {artist.portfolioUrls.length > 0 && (
                      <>
                        <Typography variant="h3">Portfolio</Typography>
                        {artist.portfolioUrls.map((url, idx) => (
                          <YouTubeVideo key={idx} url={url} />
                        ))}
                      </>
                    )}
                    {(artist.influences ||
                      artist.style ||
                      artist.performance) && (
                      <Attributes>
                        {artist.influences && (
                          <>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar alt="Influences" src={influencesIcon} />
                              </ListItemAvatar>
                              <ListItemText
                                primary="Influences"
                                secondary={artist.influences}
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </>
                        )}
                        {artist.style && (
                          <>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar alt="Style" src={styleIcon} />
                              </ListItemAvatar>
                              <ListItemText
                                primary="Style"
                                secondary={artist.style}
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </>
                        )}
                        {artist.performance && (
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar alt="Performance" src={performanceIcon} />
                            </ListItemAvatar>
                            <ListItemText
                              primary="Performance Atmosphere"
                              secondary={artist.performance}
                            />
                          </ListItem>
                        )}
                      </Attributes>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Box p={2} mt={2} textAlign="center">
              <Grid container justify="center">
                <Grid item xs sm={6}>
                  <Typography variant="h3">Artist Not Found</Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
      </LoadingData>
      <Footer />
    </>
  )
}

export default withRouter(ProfileView)
