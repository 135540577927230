const FirebaseConfig = {
  apiKey: 'AIzaSyBG8ob-O3mNULeM3USAMUcvPG0NhHSTiVk',
  authDomain: 'original-dog-871bc.firebaseapp.com',
  projectId: 'original-dog-871bc',
  storageBucket: 'original-dog-871bc.appspot.com',
  messagingSenderId: '269635750741',
  appId: '1:269635750741:web:8bf1ab4ed70ae922bdfddc',
  measurementId: 'G-4ZN3TFPR1J',
  databaseURL: 'localhost:8080'
}
export default FirebaseConfig
