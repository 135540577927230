import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Container,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { AuthContext, AuthState } from './../shared/AuthContext'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import URLMap from '../URLMap'
import logo from '../assets/logo.png'

const Root = styled.div<{ isStacked: Boolean }>`
  ${({ isStacked }) => !isStacked && `height: 3.5rem;`}
  @media (min-width: 600px) {
    ${({ isStacked }) => !isStacked && `height: 4rem;`}
  }
`

const CoreAppBar: React.FC<
  RouteComponentProps<{}> & { isStacked?: boolean }
> = ({ history, isStacked = false }) => {
  const auth = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleCloseProfileMenu = () => setAnchorEl(null)

  return (
    <Root isStacked={isStacked}>
      <AppBar
        position={'fixed'}
        elevation={0}
        style={{ backgroundColor: isStacked ? 'rgba(0, 0, 0, 0.3)' : '' }}
      >
        <Container maxWidth="lg">
          <Toolbar>
            <Link to={URLMap.root.url()}>
              <img src={logo} width="64" alt="" />
            </Link>
            <Box ml="auto" />
            <Button color="inherit" to={URLMap.about.url()} component={Link}>
              About
            </Button>
            <Button
              color="inherit"
              to={URLMap.artistList.url()}
              component={Link}
            >
              Artists
            </Button>
            {/* Ignore the case where auth.user === "RETRIEVING" */}
            {auth.authState === AuthState.UNAUTHENTICATED && (
              <Button color="inherit" to={URLMap.login.url()} component={Link}>
                Login
              </Button>
            )}
            {auth.authState === AuthState.AUTHENTICATED && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    setAnchorEl(event.currentTarget)
                  }
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleCloseProfileMenu}
                >
                  <MenuItem
                    onClick={() => {
                      history.push(URLMap.profileEdit.url())
                      handleCloseProfileMenu()
                    }}
                  >
                    Edit Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(URLMap.profileView.url(auth.user?.uid ?? ''))
                      handleCloseProfileMenu()
                    }}
                  >
                    View Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      auth.signOut().then(() => history.push(URLMap.root.url()))
                    }
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </Root>
  )
}

export default withRouter(CoreAppBar)
