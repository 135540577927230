import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Typography,
  Grid,
  Box,
  Button,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { useFuzzy } from 'react-use-fuzzy'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import heroImage from '../assets/hero-crowd-heart.jpg'
import { AuthContext, AuthState } from '../shared/AuthContext'
import { ArtistContext } from '../shared/ArtistContext'
import { CampaignContext } from '../shared/CampaignContext'
import {
  DETAILED_CAMPAIGN_STATUS,
  getDetailedStatus,
} from '../shared/types/campaign'
import CoreAppBar from '../component_library/CoreAppBar'
import { Link } from 'react-router-dom'
import Footer from '../component_library/Footer'
import URLMap from '../URLMap'
import { ArtistDocument } from '../shared/types/artist'
import { PerformanceType } from '../shared/types/PerformanceType'
import {
  LOADING,
  LoadingData,
  LoadingType,
} from '../shared/component_library/LoadingData'
import { ArtistCategories } from '../ArtistCategories'

const HeroImage = styled.div`
  background-image: url(${heroImage});
  height: 20rem;
  background-position: center;
  background-size: cover;
`
const ProfileCard = styled(Link)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  text-align: center;
  color: inherit;
  width: 175px;
  margin-left: auto;
  margin-right: auto;
`

const ProfileAvatar = styled.div<{ avatarUrl: string }>`
  background-image: url(${({ avatarUrl }) => avatarUrl});
  background-color: #000000;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  margin-bottom: 32px;
  width: 125px;
  height: 125px;
`

const ArtistName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  text-align: center;
  align-self: center;
  max-width: 100%;
`

const ArtistList = () => {
  const auth = useContext(AuthContext)
  const { getArtists, getGenres } = useContext(ArtistContext)
  const { getAllCampaigns } = useContext(CampaignContext)

  const [artistDocuments, setArtistDocuments] = useState<
    ArtistDocument[] | LoadingType
  >(LOADING)
  const [genres, setGenres] = useState<string[] | LoadingType>(LOADING)
  const [genreFilter, setGenreFilter] = useState<string | 'ALL'>('ALL')
  const [performanceTypeFilter, setPerformanceTypeFilter] = useState<
    PerformanceType | 'ALL'
  >('ALL')
  const [artistsWithActiveCampaigns, setArtistsWithActiveCampaigns] = useState<
    string[]
  >([])

  useEffect(() => {
    getArtists().then(documents => {
      setArtistDocuments(
        documents
          .filter(d => d.artist.enabled)
          .filter(
            d => genreFilter === 'ALL' || d.artist.genre.includes(genreFilter),
          )
          .filter(
            d =>
              performanceTypeFilter === 'ALL' ||
              d.artist.performanceType === performanceTypeFilter,
          ),
      )
    })
  }, [genreFilter, performanceTypeFilter, getArtists])

  useEffect(() => {
    getGenres().then(genres => setGenres(genres))
  }, [getGenres])

  const {
    result: filteredArtistDocs,
    keyword,
    search,
  } = useFuzzy<ArtistDocument>(
    artistDocuments === LOADING ? [] : artistDocuments,
    { keys: ['artist.name'] },
  )

  useEffect(() => {
    getAllCampaigns().then(documents => {
      setArtistsWithActiveCampaigns(
        documents
          .filter(
            doc =>
              getDetailedStatus(doc.campaign) ===
              DETAILED_CAMPAIGN_STATUS.ACTIVE,
          )
          .map(doc => doc.campaign.artistId),
      )
    })
  }, [getAllCampaigns])

  return (
    <>
      <CoreAppBar />
      <HeroImage />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={8}>
            <Typography variant="h1">Discover an OriginalDog</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={8}>
            <FormControl style={{ minWidth: '10rem', marginRight: '1rem' }}>
              <InputLabel>Genre</InputLabel>
              <Select
                name="genre"
                value={genreFilter}
                onChange={e => setGenreFilter(e.target.value as string)}
              >
                <MenuItem selected value="ALL">
                  All
                </MenuItem>
                {genres !== LOADING &&
                  genres.map(genre => (
                    <MenuItem key={genre} value={genre}>
                      {genre}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: '10rem', marginRight: '1rem' }}>
              <InputLabel>Type</InputLabel>
              <Select
                name="performanceType"
                value={performanceTypeFilter}
                onChange={e =>
                  setPerformanceTypeFilter(e.target.value as PerformanceType)
                }
              >
                <MenuItem selected value="ALL">
                  All
                </MenuItem>
                {Object.values(PerformanceType).map(type => {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: '10rem' }}>
              <InputLabel>Search</InputLabel>
              <Input value={keyword} onChange={e => search(e.target.value)} />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <LoadingData data={artistDocuments}>
        {artistDocuments !== LOADING &&
          (filteredArtistDocs.length === 0 ? (
            <Box p={2} mt={2} textAlign="center">
              <Grid container justify="center">
                <Grid item xs sm={8}>
                  <Typography>
                    No artists found that match the current filter
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box p={2} mt={2}>
              <Grid container justify="center">
                <Grid item xs sm={8}>
                  {ArtistCategories.categorise(filteredArtistDocs, artistsWithActiveCampaigns).map(
                    artistCategory => (
                      <div key={artistCategory.name}>
                        <Typography variant="h3">
                          {artistCategory.name}
                        </Typography>
                        <Swiper
                          style={{ height: '250px' }} // ensure the pagination bullets don't overlap with the profile cards
                          modules={[Navigation, Pagination]}
                          navigation
                          pagination={{ clickable: true }}
                          slidesPerView={1}
                          breakpoints={{
                            '640': { slidesPerView: 2 },
                            '768': { slidesPerView: 3 },
                            '1024': { slidesPerView: 4 },
                            '1408': { slidesPerView: 5 },
                            '1792': { slidesPerView: 6 },
                            '2176': { slidesPerView: 7 },
                          }}
                        >
                          {artistCategory.documents.map(({ id, artist }) => (
                            <SwiperSlide key={id}>
                              <ProfileCard to={URLMap.profileView.url(id)}>
                                <ProfileAvatar avatarUrl={artist.avatarUrl} />
                                <ArtistName>{artist.name}</ArtistName>
                              </ProfileCard>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    ),
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
      </LoadingData>
      {auth.authState === AuthState.UNAUTHENTICATED && (
        <Box p={2} mt={2}>
          <Grid container justify="center">
            <Box textAlign="center" mt={2}>
              <Typography>Do you make original music?</Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                to={URLMap.register.url()}
                component={Link}
              >
                Sign up
              </Button>
            </Box>
          </Grid>
        </Box>
      )}
      <Footer />
    </>
  )
}

export default ArtistList
