import {
  Avatar,
  Box,
  Button,
  Chip,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Field, FieldArray, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import CoreAppBar from '../component_library/CoreAppBar'
import Footer from '../component_library/Footer'
import { ArtistContext } from '../shared/ArtistContext'
import { AuthContext, AuthState } from '../shared/AuthContext'
import {
  LOADING,
  LoadingData,
  LoadingType,
} from '../shared/component_library/LoadingData'
import { MAX_PROFILE_GENRES } from '../shared/Constants'
import { SnackbarContext } from '../shared/SnackbarContext'
import { Artist, defaultArtist } from '../shared/types/artist'
import { PerformanceType } from '../shared/types/PerformanceType'
import URLMap from '../URLMap'
import Gallery from './Gallery'

enum ACTIVE_IMAGE_FIELD {
  NONE = '',
  AVATAR = 'avatarUrl',
  BACKGROUND = 'backgroundUrl',
}

const Profile: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps,
) => {
  const auth = useContext(AuthContext)
  const { getArtist, updateArtist, getGenres } = useContext(ArtistContext)
  const { wrapWithSnackbar } = useContext(SnackbarContext)
  const [artist, setArtist] = useState<Artist | LoadingType>(LOADING)
  const [genres, setGenres] = useState<string[]>([])
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [activeImageField, setActiveImageField] = useState(
    ACTIVE_IMAGE_FIELD.NONE,
  )

  useEffect(() => {
    if (auth.user?.uid) {
      getArtist(auth.user.uid).then(artist =>
        artist ? setArtist(artist) : setArtist(defaultArtist()),
      )
    } else {
      setArtist(defaultArtist())
    }
  }, [auth.user, getArtist])

  useEffect(() => {
    getGenres().then(knownGenres => setGenres(knownGenres))
  }, [getGenres])

  const updateImage = (field: ACTIVE_IMAGE_FIELD, url: string) => {
    if (auth.user?.verified && field !== ACTIVE_IMAGE_FIELD.NONE) {
      updateArtist(auth.user.uid, {
        [field]: url,
      })
    }
  }

  if (auth.authState === AuthState.UNAUTHENTICATED) {
    props.history.push(URLMap.login.url())
    return <></>
  }

  if (auth.authState === AuthState.AUTHENTICATED && !auth.user?.verified) {
    props.history.push(URLMap.profileNotVerified.url())
    return <></>
  }

  const onNewGenre = (formik: any) => {
    const newGenre = formik.values.newGenre
    setGenres(genres.concat(newGenre))
    formik.setFieldValue('genre', formik.values.genre.concat(newGenre))
    formik.setFieldValue('newGenre', '')
  }

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs md={8} lg={6}>
            {auth.authState === AuthState.RETRIEVING && <CircularProgress />}
            {auth.user?.verified && (
              <LoadingData data={artist}>
                {artist !== LOADING && (
                  <>
                    <Typography>Name: {artist.name}</Typography>
                    <Typography>Email: {auth.user.email}</Typography>
                    <Formik
                      initialValues={Object.assign(artist, { newGenre: '' })}
                      enableReinitialize
                      onSubmit={form => {
                        // remove the newGenre before updating the artist
                        const { newGenre, ...artist} = form
                        setIsFormSubmitting(true)
                        wrapWithSnackbar(
                          updateArtist(
                            auth.user?.uid ?? null,
                            artist,
                          ).finally(() => setIsFormSubmitting(false)),
                        )
                      }}
                    >
                      {props => (
                        <form onSubmit={props.handleSubmit}>
                          <Typography variant="h3">General</Typography>
                          <Box mt={2}>
                            <Avatar
                              alt="Avatar image"
                              src={artist.avatarUrl}
                              style={{ width: '8rem', height: '8rem' }}
                            />
                            <IconButton
                              size="small"
                              onClick={() =>
                                setActiveImageField(ACTIVE_IMAGE_FIELD.AVATAR)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                          <Box mt={2}>
                            <img
                              src={artist.backgroundUrl}
                              style={{
                                width: '16rem',
                                height: '9rem',
                                display: 'block',
                                backgroundColor: '#000',
                              }}
                              alt="Profile Background"
                            />
                            <IconButton
                              size="small"
                              onClick={() =>
                                setActiveImageField(
                                  ACTIVE_IMAGE_FIELD.BACKGROUND,
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="city"
                              label="City"
                              variant="outlined"
                              fullWidth
                              value={props.values.city}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="state"
                              label="State"
                              variant="outlined"
                              fullWidth
                              value={props.values.state}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="tagline"
                              label="Tagline"
                              variant="outlined"
                              fullWidth
                              value={props.values.tagline}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="profileVideoUrl"
                              label="Profile Video Url"
                              variant="outlined"
                              fullWidth
                              value={props.values.profileVideoUrl}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="originStory"
                              label="Origin Story"
                              multiline
                              rowsMax={4}
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={props.values.originStory}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="ambitions"
                              label="Ambitions"
                              multiline
                              rowsMax={4}
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={props.values.ambitions}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="challenges"
                              label="Write about the struggles you or your band has had"
                              multiline
                              rowsMax={4}
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={props.values.challenges}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="achievements"
                              label="Professional accomplishments"
                              multiline
                              rowsMax={4}
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={props.values.achievements}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="influences"
                              label="What influences your style"
                              multiline
                              rowsMax={4}
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={props.values.influences}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="style"
                              label="Describe your style of music"
                              multiline
                              rowsMax={4}
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={props.values.style}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="performance"
                              label="What's it like being at one of your gigs"
                              multiline
                              rowsMax={4}
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={props.values.performance}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <FormControl fullWidth>
                              <InputLabel>Genre</InputLabel>
                              <Select
                                name="genre"
                                multiple
                                value={props.values.genre}
                                onChange={props.handleChange}
                                renderValue={selected => {
                                  return (
                                    <Box>
                                      {(selected as string[]).map(genre => (
                                        <Chip label={genre} key={genre} />
                                      ))}
                                    </Box>
                                  )
                                }}
                              >
                                {genres.map(genre => {
                                  const isSelected = props.values.genre.some(
                                    g => g === genre,
                                  )
                                  return (
                                    <MenuItem
                                      key={genre}
                                      value={genre}
                                      disabled={
                                        !isSelected &&
                                        props.values.genre.length >=
                                          MAX_PROFILE_GENRES
                                      }
                                    >
                                      <Checkbox checked={isSelected} />
                                      <ListItemText primary={genre} />
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                            <TextField
                              label="New Genre (optional)"
                              variant="outlined"
                              name="newGenre"
                              value={props.values.newGenre}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                            <Button
                              color="primary"
                              onClick={() => onNewGenre(props)}
                              disabled={
                                props.values.genre.length >= MAX_PROFILE_GENRES ||
                                props.values.newGenre.length === 0 ||
                                genres.includes(props.values.newGenre)
                              }
                            >
                              ADD
                            </Button>
                          </Box>
                          <Box mt={2}>
                            <FormControl fullWidth>
                              <InputLabel>Type</InputLabel>
                              <Select
                                name="performanceType"
                                value={props.values.performanceType}
                                onChange={props.handleChange}
                              >
                                {Object.values(PerformanceType).map(type => {
                                  return (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Typography variant="h3">Video Portfolio</Typography>
                          <FieldArray name="portfolioUrls">
                            {({ remove, push }) => (
                              <>
                                {props.values.portfolioUrls.map((url, idx) => (
                                  <div key={idx}>
                                    {/* TODO: The DeleteIcon should be on the same line as the url */}
                                    <IconButton onClick={() => remove(idx)}>
                                      <DeleteIcon />
                                    </IconButton>
                                    <Field
                                      as={TextField}
                                      name={`portfolioUrls.${idx}`}
                                      label={`video url ${idx + 1}`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                ))}
                                <IconButton onClick={() => push('')}>
                                  <AddIcon />
                                </IconButton>
                              </>
                            )}
                          </FieldArray>
                          <Typography variant="h3">Social</Typography>
                          <Box mt={2}>
                            <TextField
                              name="facebookUrl"
                              label="Facebook Url"
                              variant="outlined"
                              fullWidth
                              value={props.values.facebookUrl}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="twitterUrl"
                              label="Twitter Url"
                              variant="outlined"
                              fullWidth
                              value={props.values.twitterUrl}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="instagramUrl"
                              label="Instagram Url"
                              variant="outlined"
                              fullWidth
                              value={props.values.instagramUrl}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="youTubeUrl"
                              label="YouTube Url"
                              variant="outlined"
                              fullWidth
                              value={props.values.youTubeUrl}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="spotifyUrl"
                              label="spotify Url"
                              variant="outlined"
                              fullWidth
                              value={props.values.spotifyUrl}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              name="appleMusicUrl"
                              label="Apple Music Url"
                              variant="outlined"
                              fullWidth
                              value={props.values.appleMusicUrl}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                            />
                          </Box>
                          <Box mt={2}>
                            <FormControlLabel
                              name="enabled"
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                              value={props.values.enabled}
                              checked={props.values.enabled}
                              control={<Checkbox color="primary" />}
                              label="Publish profile"
                            />
                          </Box>
                          <Box mt={2}>
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={isFormSubmitting}
                            >
                              Save
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
              </LoadingData>
            )}
          </Grid>
        </Grid>
      </Box>
      <Footer />
      <Gallery
        open={activeImageField !== ACTIVE_IMAGE_FIELD.NONE}
        onClose={() => setActiveImageField(ACTIVE_IMAGE_FIELD.NONE)}
        onSelected={url => updateImage(activeImageField, url)}
      />
    </>
  )
}

export default withRouter(Profile)
