import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`

const Player = styled(ReactPlayer).attrs({ width: '100%', height: '100%' })`
  position: absolute;
  top: 0;
  left: 0;
`

const Video: React.FC<{ url: string }> = ({ url, ...props }) => {
  return (
    <Root {...props}>
      <Player url={url} />
    </Root>
  )
}

export default Video
