import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button, Typography, Grid, Box, TextField } from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import { useFormik } from 'formik'
import Footer from '../component_library/Footer'
import heroImage from '../assets/audience-background.jpg'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import URLMap from '../URLMap'
import { AuthContext, AuthState } from '../shared/AuthContext'
import { CampaignContext } from '../shared/CampaignContext'
import { defaultCampaign } from '../shared/types/campaign'

const HeroImage = styled.div`
  background-image: url(${heroImage});
  height: 20rem;
  background-position: center;
  background-size: cover;
`

const CreateCampaign: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps,
) => {
  const auth = useContext(AuthContext)
  const campaignCtx = useContext(CampaignContext)
  const [campaign, setCampaign] = useState(defaultCampaign())
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')

  useEffect(
    () =>
      setCampaign(Object.assign(campaign, { artistId: auth.user?.uid ?? '' })),
    [auth.user, campaign],
  )

  const formik = useFormik({
    initialValues: campaign,
    onSubmit: campaign => {
      // add a 5% fee to the campaign goal
      const modifiedCampaign = Object.assign(campaign, {goal: Math.round(1.05 * campaign.goal)})
      setFormErrorMessage('')
      setIsFormSubmitting(true)
      campaignCtx
        .createCampaign(modifiedCampaign)
        .then(() =>
          props.history.push(URLMap.profileView.url(campaign.artistId)),
        )
        .catch(error => {
          setFormErrorMessage(error.message)
          setIsFormSubmitting(false)
        })
    },
    validate: campaign => {
      return {
        ...(campaign.title.trim().length === 0 && {
          title: 'Please provide a title',
        }),
        ...(campaign.description.trim().length === 0 && {
          description: 'Please provide a description',
        }),
        ...(campaign.goal <= 0 && {
          goal: 'Please provide a positive goal amount',
        }),
        ...(campaign.videoUrl.trim().length === 0 && {
          videoUrl: 'Please provide a video URL',
        }),
        ...(campaign.city.trim().length === 0 && {
          city: 'Please provide a city',
        }),
        ...(campaign.contactNumber.trim().length === 0 && {
          contactNumber: 'Please provide a contact number',
        }),
      }
    },
  })

  if (auth.authState === AuthState.UNAUTHENTICATED) {
    props.history.push(URLMap.login.url())
    return <></>
  }

  if (auth.authState === AuthState.AUTHENTICATED && !auth.user?.verified) {
    props.history.push(URLMap.profileNotVerified.url())
    return <></>
  }

  return (
    <>
      <CoreAppBar />
      <HeroImage />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h3">Create Campaign</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <form onSubmit={formik.handleSubmit}>
              <Box mt={2}>
                <TextField
                  error={formik.touched.title && formik.errors.title != null}
                  name="title"
                  label="Title"
                  type="text"
                  variant="outlined"
                  helperText={
                    formik.touched.title && formik.errors.title
                      ? formik.errors.title
                      : null
                  }
                  fullWidth
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  error={
                    formik.touched.description &&
                    formik.errors.description != null
                  }
                  name="description"
                  label="Description"
                  type="text"
                  variant="outlined"
                  multiline
                  rows={6}
                  rowsMax={10}
                  helperText={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : null
                  }
                  fullWidth
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box mt={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="Start Date"
                    format="dd/MM/yyyy"
                    value={formik.values.start}
                    onBlur={formik.handleBlur}
                    onChange={value => formik.setFieldValue('start', value?.getTime())}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box mt={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="End Date"
                    format="dd/MM/yyyy"
                    value={formik.values.end}
                    onBlur={formik.handleBlur}
                    onChange={value => formik.setFieldValue('end', value?.getTime())}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box mt={2}>
                <TextField
                  error={formik.touched.goal && formik.errors.goal != null}
                  name="goal"
                  label="Goal Amount"
                  type="number"
                  variant="outlined"
                  helperText={
                    formik.touched.goal && formik.errors.goal
                      ? formik.errors.goal
                      : null
                  }
                  fullWidth
                  value={formik.values.goal}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <Typography component="p" color="textSecondary">Note: the campaign goal will be displayed as {Math.round(formik.values.goal * 1.05)} Rs to account for a 5% processing fee.</Typography>
              </Box>
              <Box mt={2}>
                <TextField
                  error={
                    formik.touched.videoUrl && formik.errors.videoUrl != null
                  }
                  name="videoUrl"
                  label="Video URL"
                  type="text"
                  variant="outlined"
                  helperText={
                    formik.touched.videoUrl && formik.errors.videoUrl
                      ? formik.errors.videoUrl
                      : null
                  }
                  fullWidth
                  value={formik.values.videoUrl}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  error={formik.touched.city && formik.errors.city != null}
                  name="city"
                  label="City"
                  type="text"
                  variant="outlined"
                  helperText={
                    formik.touched.city && formik.errors.city
                      ? formik.errors.city
                      : null
                  }
                  fullWidth
                  value={formik.values.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  error={
                    formik.touched.contactNumber &&
                    formik.errors.contactNumber != null
                  }
                  name="contactNumber"
                  label="Contact Number"
                  type="text"
                  variant="outlined"
                  helperText={
                    formik.touched.contactNumber && formik.errors.contactNumber
                      ? formik.errors.contactNumber
                      : null
                  }
                  fullWidth
                  value={formik.values.contactNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box textAlign="center" mt={2}>
                <Typography component="p" color="secondary">
                  {formErrorMessage.length ? formErrorMessage : '\u00A0'}
                </Typography>
              </Box>
              <Box textAlign="center" mt={2}>
                <Button
                  disabled={isFormSubmitting}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Submit Request
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default withRouter(CreateCampaign)
